<template>
  <v-dialog
      v-model="viewer"
      max-width="1200"
  >
    <v-card height="80vh" :loading="loading">
      <v-card-title class="primary white--text text-h5">
        {{ title }}
      </v-card-title>

      <v-card-text class="p-3 primary">
        <v-sheet v-if="contents.length !== 0" color="pa-4 white">
<!--          {{contents}}-->
          <div v-for="(content, index) in contents" :key="index">

            <!-- Head Viewer -->
            <template v-if="content.type === 'head'">
              <h2>{{ content['head'+lang] }}</h2>
            </template>

            <!-- Text Viewer -->
            <p v-if="content.type === 'text'">{{ content['text'+lang] }}</p>

            <!-- Image Viewer -->
            <template v-if="content.type === 'image'">
              <v-row class="mx-10">
                <v-col cols="auto" class="text-center">
                  <img class="v-responsive" v-if='content.image.length' :src="content.image"/>
                </v-col>
                <v-col cols="auto" v-if="content['legend'+lang].length">
                  <v-sheet
                      class="pa-4 text-white mx-auto"
                      color="primary"
                      max-width="400"
                      style="white-space: pre-wrap"
                  >{{ content['legend'+lang] }}
                  </v-sheet>
                </v-col>
              </v-row>
            </template>

            <!-- TODO Table Viewer -->
            <br>
          </div>
        </v-sheet>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/api";
import {mapState} from "vuex";

export default {
  name: "ProcedureViewer",
  props: ['doc', 'title'],
  data() {
    return {
      viewer: false,
      loading: true,
      contents: []
    }
  },
  computed: {
    ...mapState([
      'language'
    ]),
    lang() {
      return '_'+this.language
    }
  },
  methods: {
    show() {
      this.viewer = true
    },
  },
  watch: {
    doc(value) {
      if (value !== '') {
        api.GET_PROCEDURE(this.doc)
            .then(response => {
              this.contents = response.data.contents
              this.loading = false
            })
      }
    }

  }

}
</script>

<style scoped>
html {
  overflow: hidden !important;
}

.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>