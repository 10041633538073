<template>
  <v-card dark width="350" :loading="history.length == 0">
    <v-list dense class="pa-3">
      <v-subheader>
        <v-icon left small>mdi-history</v-icon>
        {{ TEXT('latest_10_vehicles') }}
      </v-subheader>

      <v-slide-y-transition group>
        <v-list-item
            link
            @click="selectVehicle(vehicle)"
            v-for="vehicle in history"
            :key="vehicle['ID']">
          <v-list-item-content>
            <v-list-item-title>
              {{ vehicle['MARCA'] }} <small>{{ vehicle['MODELO'] }}</small>
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-tooltip bottom
                         color="primary"
                         content-class="custom-tooltip"
                         open-delay="250"
              >
                <template v-slot:activator="{ on, attrs }">
                <span
                    v-bind="attrs"
                    v-on="on"
                >{{ subtitleText(vehicle) }}</span>
                </template>
                <span>{{ subtitleText(vehicle) }}</span>
              </v-tooltip>
            </v-list-item-subtitle>
          </v-list-item-content>

        </v-list-item>
      </v-slide-y-transition>

    </v-list>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "HistorySelector",
  props: ['history'],
  computed: {
    ...mapGetters(['TEXT']),
  },
  methods: {

    subtitleText(vehicle) {
      return vehicle['MOTORIZACION']
          + " (" + vehicle['MOTORES'] + ")"
          + " [" + vehicle['DESDE'] + " / " + vehicle['HASTA'] + "]"
    },

    selectVehicle(selected) {
      let vehicle = {}
      vehicle.maker = selected['MARCA']
      vehicle.makerID = selected['makerID']
      vehicle.model = selected['MODELO']
      vehicle.modelID = selected['modelID']
      vehicle.motor = selected['MOTORES']
      vehicle.vehicleID = selected['ID']
      vehicle.extcode = selected['ID']
      this.$store.commit('SET_VEHICLE', vehicle)
      this.$store.commit('CHANGE_TSB_VIEW', 3)
    }
  }
}
</script>

<style scoped>
  .custom-tooltip {
    opacity: 1 !important;
  }
</style>

