<template>
  <v-container fluid>

    <v-card dark>
      <v-card-text>
        <v-text-field class="mx-4 mt-4"
                      v-model="filter"
                      placeholder="Buscar"
                      outlined
                      prepend-inner-icon="mdi-magnify"
                      color="primary" style="max-width: 300px"></v-text-field>
        <v-list>
          <v-list-item link
                       v-for="procedure in filteredList"
                       :key="procedure.key"
                       @click="show(procedure)"><v-icon left>mdi-text</v-icon>{{ procedure.title }}
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>

    <procedure-viewer :title="title" :doc="doc" ref="viewer"></procedure-viewer>


  </v-container>
</template>

<script>
import {mapState} from "vuex";
import api from "@/api";
import ProcedureViewer from "@/components/ProcedureViewer.vue";

export default {
  name: "procedure-view",
  components: {ProcedureViewer},
  data() {
    return {
      viewer: false,
      procedures: [],
      title: '',
      doc: '',
      filter: ''
    }
  },
  computed: {
    ...mapState([
      'language'
    ]),
    filteredList() {
      let filter = this.filter
      return this.procedures.filter(procedure => {
        return procedure.title.toLowerCase().includes(filter.toLowerCase());
      })
    }
  },
  methods: {
    show(procedure) {
      this.title = procedure.title
      this.doc = procedure.key
      this.$refs.viewer.show()
    },
  },
  mounted() {
    api.GET_PROCEDURE_LIST()
        .then(response => {
          let lang = this.language
          let list = response.data.rows.filter(item => {
            let title = item.value['title_'+lang]
            if (!title) { return false }
            item.title = title
            return true
          })
          console.log(list)
          this.procedures = list
        })
  }
}
</script>