<template>
  <v-container>
    <h1>
      <v-icon left x-large color="black" class="pb-2">mdi-robot</v-icon>
      IA
    </h1>
    <v-alert
        border="bottom"
        colored-border
        type="error"
        elevation="4"
        class="mb-5"
    >
      <b style="color: #ff5252 !important">{{ TEXT('warning') }}:</b> <span v-html="TEXT('ia_warning')"></span>
    </v-alert>
    <v-row>
      <v-col
          cols="12"
          md="6"
      >
        <v-text-field
            name="ask"
            :label="TEXT('inquiry')"
            v-model="query"
            hint=""
            outlined
            autofocus
            @keyup.enter.prevent="ask"
        ></v-text-field>
        <div class="text-right">
          <v-btn color="primary" @click="ask" :disabled="!query.length"><v-icon left>mdi-chat</v-icon>
            {{ TEXT('make_inquiry') }}</v-btn>
        </div>
      </v-col>
      <v-divider></v-divider>
      <v-col
          cols="12"
          md="6"
      >
        <v-textarea
            name="response"
            :label="TEXT('response')"
            v-model="response"
            outlined
            no-resize
            readonly
            auto-grow
            :loading="waiting"
            :disabled="!response.length"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import api from "@/api";

export default {
  name: "IAChatView",
  data() {
    return {
      query: '',
      response: '',
      waiting: false
    }
  },
  computed: {
    ...mapGetters(['TEXT']),
    ...mapState([
      'language'
    ]),
  },
  methods: {
    ask() {
      this.waiting = true
      api.ASK_TO_GPT(this.query, this.language)
          .then(response => {
            if (response.data.auth) {
              this.response = response.data.completion.choices[0].message.content;
              this.waiting = false
            } else {
              this.$router.push('/')
            }
          })

    }
  }
}
</script>

<style scoped>

</style>