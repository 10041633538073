<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-auto">
        <div class="d-inline-flex">
          <img :src="'https://assets.infotechnic.es/logos/'+vehicle.maker.toLowerCase()+'.png'" alt=""
               class="img-fluid mr-2" style="height: 50px">
          <h1>{{ vehicle.maker }}</h1>
        </div>
      </div>
      <div class="col-auto pt-2">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><v-icon>mdi-magnify</v-icon></span>
          </div>
          <input type="text" class="form-control" :placeholder="TEXT('code_search')+' DTC'" v-model="search">
        </div>
      </div>
    </div>
    <hr class="mt-1">
    <div class="container" v-if="search.length == 0">
      <table class="table table-sm table-dark table-striped table-hover">
        <thead class="bg-primary">
        <tr>
          <th>{{ TEXT('model') }}</th>
          <th>{{ TEXT('from') }}</th>
          <th>{{ TEXT('to') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="m in models" @click="setModel(m)" :key="m.id">
          <td>{{ m.DESCRIPCION }}</td>
          <td>{{ m.DESDE }}</td>
          <td>{{ m.HASTA }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <template v-if="search.length > 0">
      <h2 class="mb-3">{{ TEXT('code_search') }} {{ search }}</h2>
      <div class="row" v-if="dtcs.length > 0">
        <div class="col-12">
          <table class="table table-sm table-dark table-striped table-hover">
            <thead class="bg-primary">
            <tr>
              <th>{{ TEXT('manufacturer') }}</th>
              <th>DTC</th>
              <th>{{ TEXT('description') }}</th>
              <th>{{ TEXT('reason') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="d in dtcs" :key="d.id">
              <td>{{ d.make }}</td>
              <td>{{ d.dtc }}</td>
              <td>{{ d.description }}</td>
              <td>{{ d.reason }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--      <bulletin-selector v-if="bulletins.length > 0" :custom-list="bulletins"></bulletin-selector>-->
      <!-- TODO MIGRATE TO COMPONENT -->
      <div class="row">
        <div class="col-12 col-md-4">
          <label class="mb-1 d-block d-md-none">Selecciona un boletín:</label>
          <select class="custom-select custom-select-sm d-block d-md-none mb-4 bg-primary text-white"
                  v-model="bulletin">
            <option v-for="(b, index) in bulletins" :value="index" :key="index">{{ b.title }}</option>
          </select>
          <select class="form-control d-none d-md-block" :size="bulletins.length" v-model="bulletin">
            <option v-for="(b, index) in bulletins" :value="index" :key="index">{{ b.title }}</option>
          </select>
        </div>
        <div class="col-12 col-md-8">
          <template v-if="bulletins.length > 0">
            <bulletin-viewer :bulletin="bulletins[bulletin]" v-if="bulletins.length > 0"></bulletin-viewer>
            <br>
            <p style="color:white">{{ bulletins[bulletin].bulletin_id }}</p>
          </template>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import api from "@/api";
import {mapGetters, mapState} from "vuex";
import BulletinViewer from "@/components/bulletins/BulletinViewer.vue";

export default {
  name: "ModelSelector",
  components: {BulletinViewer},
  data() {
    return {
      models: [],
      search: '',
      bulletin: 0,
      bulletins: [],
      dtcs: [],
    }
  },
  computed: {
    ...mapGetters(['TEXT']),
    ...mapState([
      'vehicle'
    ]),
  },
  methods: {
    listModels() {
      api.GET_MODELS(this.vehicle.makerID)
          .then(response => {
            if (!response.data.auth) {
              this.$emit('logout')
            } else {
              this.models = response.data.list
            }
          })
    },
    setModel(model) {
      let vehicle = this.vehicle
      vehicle.model = model.DESCRIPCION
      vehicle.modelID = model.ID
      this.$store.commit('SET_VEHICLE', vehicle)
      this.$store.commit('CHANGE_TSB_VIEW', 2)

    },
    newSearch(str) {
      api.GET_NEW_SEARCH(this.vehicle.makerID, str)
          .then(response => {
            if (!response.data.auth) {
              this.logout()
            } else {
              this.bulletins = response.data.list
              this.dtcs = response.data.dtcs
            }
          });
    },
  },
  created() {
    this.listModels();
    if (!this.vehicle.makerID) {
      this.$store.commit('CHANGE_TSB_VIEW', 0)
    }
  },
  watch: {
    search: function (val) {
      let current = val
      setTimeout(() => {
        if (this.search == current) {
          this.newSearch(current)
        }
      }, 1000); // 1/4 sec delay
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../theme';
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
</style>

<style scoped>
table td {
  cursor: pointer;
}
</style>
